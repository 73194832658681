@font-face {
    font-family: 'Ferrari Sans';
    src: url(assets/fonts/Ferrari-SansRegular.ttf);
    font-weight: normal;
}

@font-face {
    font-family: 'Ferrari Sans';
    src: url(assets/fonts/Ferrari-SansBold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Ferrari Sans';
    src: url(assets/fonts/Ferrari-SansLight.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Ferrari Sans';
    src: url(assets/fonts/Ferrari-SansMedium.ttf);
    font-weight: 500;
}

main {
    background: url(assets/images/bkg.jpg) no-repeat center;
    background-size: cover;
    width: 100%;
    position: relative;
    min-height: max(100vh, 60rem);
    font-family: "Ferrari Sans", sans-serif;
}

.logoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
}

.formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem 0;
}

@media screen and (max-width: 768px) {
    main {
        justify-content: flex-start;
        min-height: max(100vh, 85rem);
    }

    .logoHeader {
        flex-direction: column;
        text-align: center;
        padding-top: 2rem;
    }

    .formRow {
        flex-direction: column;
        text-align: left;
    }
}

@media (max-resolution: 120dpi) {
    html {
        font-size: 80%;
    }
}

.footer-contact {
    background: linear-gradient(0deg, rgba(21,21,23,1) 0%, rgba(10,10,12,1) 79%);
    border: 0px;
    border-top: 4px solid;
    border-image: linear-gradient(to right, rgb(237, 28, 35), rgb(0, 0, 0));
    border-image-slice: 1
}

.testo-time input {
    text-transform: uppercase;
}
